import { render, staticRenderFns } from "./MyDocumentsDashboardItem.vue?vue&type=template&id=1ffdd631&scoped=true"
import script from "./MyDocumentsDashboardItem.vue?vue&type=script&lang=js"
export * from "./MyDocumentsDashboardItem.vue?vue&type=script&lang=js"
import style0 from "./MyDocumentsDashboardItem.vue?vue&type=style&index=0&id=1ffdd631&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ffdd631",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VBtn,VCardText,VCol,VDivider,VIcon,VImg,VList,VListItem,VListItemContent,VListItemGroup,VListItemIcon,VProgressCircular,VRow,VSkeletonLoader})
