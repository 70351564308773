import { render, staticRenderFns } from "./UsedBenefitsDashboardItemSbaLegacy.vue?vue&type=template&id=88168a64&scoped=true"
import script from "./UsedBenefitsDashboardItemSbaLegacy.vue?vue&type=script&lang=js"
export * from "./UsedBenefitsDashboardItemSbaLegacy.vue?vue&type=script&lang=js"
import style0 from "./UsedBenefitsDashboardItemSbaLegacy.vue?vue&type=style&index=0&id=88168a64&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88168a64",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCheckbox,VCol,VList,VListItem,VListItemAction,VListItemContent,VListItemGroup,VListItemTitle,VProgressCircular,VRow})
