import { render, staticRenderFns } from "./BenefitCardsDashboardItemSba.vue?vue&type=template&id=f00b9d0c&scoped=true"
import script from "./BenefitCardsDashboardItemSba.vue?vue&type=script&lang=js"
export * from "./BenefitCardsDashboardItemSba.vue?vue&type=script&lang=js"
import style0 from "./BenefitCardsDashboardItemSba.vue?vue&type=style&index=0&id=f00b9d0c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f00b9d0c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VBtn,VBtnToggle,VCarousel,VCarouselItem,VCol,VIcon,VRow,VSheet,VSkeletonLoader,VSnackbar})
