import { render, staticRenderFns } from "./BenefitBalanceDashboardItem.vue?vue&type=template&id=6198054a"
import script from "./BenefitBalanceDashboardItem.vue?vue&type=script&lang=js"
export * from "./BenefitBalanceDashboardItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
installComponents(component, {VCardText,VCardTitle})
