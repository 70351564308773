import { render, staticRenderFns } from "./WelcomeDashboardItem.vue?vue&type=template&id=a8675b0a&scoped=true"
import script from "./WelcomeDashboardItem.vue?vue&type=script&lang=js"
export * from "./WelcomeDashboardItem.vue?vue&type=script&lang=js"
import style0 from "./WelcomeDashboardItem.vue?vue&type=style&index=0&id=a8675b0a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8675b0a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
installComponents(component, {VCardText})
